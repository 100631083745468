import apiClient from 'Services/ApiSauceInstance'
import { ApiResponse } from 'Services/api-response'
import { CancelToken } from 'axios'

export interface AssetImport {
  manufacturerName: string;
  assetTypeName: string;
  assetModelName: string;
  serialNumber: string;
  alias?: string | null;
}

export interface Asset {
  id: string;
  manufacturerId: string;
  assetTypeId: string;
  serialNumber: string;
  alias?: string | null;
}

export interface ImportAssetsRequest {
  siteId: string;
  assets: AssetImport[];
  dryRun?: boolean;
}

export interface DeleteAssetsRequest {
  serialNumbers: string[];
}

export type ImportAssetsResponse = ApiResponse<ApiResponse<Asset>[]>

export const importAssets = (request: ImportAssetsRequest, cancelToken?: CancelToken) => apiClient
  .post<ImportAssetsResponse>('assets:import', request,  cancelToken )

export const deleteAssets = (request: DeleteAssetsRequest, cancelToken?: CancelToken) => apiClient
  .delete<ApiResponse<any>>(
    'devices',
    {},
    {
      data: {
        'filter': {
          'serialNumber': {
            '$in': request.serialNumbers
          }
        }
      },
      timeout: 10000,
      cancelToken
    })
